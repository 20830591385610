<template>
  <div class="wrap">
    <div class="header">
      <headerTop></headerTop>
    </div>
    <!-- 员工活动 -->
    <div class="staff_activities" ref="staff">
      <div class="top">
        <h3 class="title">员工活动</h3>
        <i class="i"></i>
        <span class="span">一个企业的发展离不开员工<br />睿见科技始终以一颗感恩的心感谢员工们的热情工作与辛苦付出<br />努力为员工的发展提供最大的支持</span>
      </div>
      <div class="content_box contanier">
        <div :class="['box', classData[classIndex]]">
          <div class="item dOne" @mouseenter="mouseenter('0')" @mouseleave="mouseleave('0')">
            <i class="line" v-show="showflag['0']"></i>
            <span class="tit" v-show="showflag['0']">公司年会</span>
            <span class="text" v-show="showflag['0']">回顾以往，展望未来。</span>
            <p class="hover_tit" v-show="!showflag['0']">公司年会</p>
            <img src="../assets/img/member/member_conpany_02.png" alt="" v-show="!showflag['0']" />
          </div>
          <div class="item dTwo" @mouseenter="mouseenter('1')" @mouseleave="mouseleave('1')">
            <i class="line" v-show="showflag['1']"></i>
            <span class="tit" v-show="showflag['1']">周年庆</span>
            <span class="text" v-show="showflag['1']">欢庆周年，继续前行。</span>
            <p class="hover_tit" v-show="!showflag['1']">周年庆</p>
            <img src="../assets/img/member/member_anniversary_02.png" alt="" v-show="!showflag['1']" />
          </div>
          <div class="item dThree" @mouseenter="mouseenter('2')" @mouseleave="mouseleave('2')">
            <i class="line" v-show="showflag['2']"></i>
            <span class="tit" v-show="showflag['2']">新员工交流会</span>
            <span class="text" v-show="showflag['2']">传递理念，增进感情。</span>
            <p class="hover_tit" v-show="!showflag['2']">新员工交流会</p>
            <img src="../assets/img/member/member_new_02.png" alt="" v-show="!showflag['2']" />
          </div>
          <div class="item dFour" @mouseenter="mouseenter('3')" @mouseleave="mouseleave('3')">
            <i class="line" v-show="showflag['3']"></i>
            <span class="tit" v-show="showflag['3']">健身激励</span>
            <span class="text" v-show="showflag['3']">关爱员工健康，丰富业余生活。</span>
            <p class="hover_tit" v-show="!showflag['3']">健身激励</p>
            <img src="../assets/img/member/member_keep_02.png" alt="" v-show="!showflag['3']" />
          </div>
        </div>
      </div>
    </div>
    <!-- 办公环境 -->
    <div class="office contanier" ref="office">
      <h3 class="title">办公环境</h3>
      <i class="i"></i>
      <span class="span"
        >公司总部设立于科教智力和人才资源最为密集的区域——中关村soho大厦<br />
        拥有“东方硅谷”之称的北京中关村西区是集高档写字楼、星级酒店、大型企业总部、商业配套为一体的立体多功能核心商务区<br />
        且公共交通发达，紧邻地铁4号线、10号线，40余条公交线路环绕</span
      >
      <div class="contentBox">
        <ul>
          <li>
            <img src="../assets/img/member/member_bottom01.png" alt="" />
            <span class="tit">中关村soho大厦</span>
          </li>
          <li>
            <img src="../assets/img/member/member_bottom02.png" alt="" />
            <span class="tit">公司前台</span>
          </li>
          <li>
            <img src="../assets/img/member/member_bottom03.png" alt="" />
            <span class="tit">休息区域</span>
          </li>
        </ul>
      </div>
    </div>
    <pageFooter @goAnchor="goAnchor"></pageFooter>
  </div>
</template>

<script>
import headerTop from '@/components/header.vue'
import pageFooter from '@/components/footer.vue'
export default {
  name: 'Member',
  data() {
    return {
      // anchor: ''
      showflag: {
        0: true,
        1: true,
        2: true,
        3: true,
        4: true
      },
      state: false,
      classData: {
        0: 'active0',
        1: 'active1',
        2: 'active2',
        3: 'active3',
        4: ''
      },

      classIndex: '4'
    }
  },
  components: {
    headerTop,
    pageFooter
  },
  created() {
    window.scroll(0, 0)
  },
  mounted() {
    // 判断是否是从底部跳转过来的
    // console.log(this.$route.params)
    if (this.$route.params.anchor) {
      this.$refs[this.$route.params.anchor].scrollIntoView(true)
    }
  },
  methods: {
    // 锚点跳转
    goAnchor(val) {
      // console.log(val)
      this.$refs[val].scrollIntoView(true)
    },
    mouseenter(i) {
      // this.classIndex = 0
      // this.flag = !this.flag
      // this.state = !this.state
      this.classIndex = i
      // 遍历对象
      for (var k in this.showflag) {
        // console.log(k)
        this.showflag[k] = true
      }
      this.showflag[i] = false
    },
    mouseleave(i) {
      // this.classIndex = 4
      // this.flag = !this.flag
      // this.state = !this.state
      this.classIndex = '4'
      this.showflag[i] = true
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: #fff;
  z-index: 999;
}
.wrap {
  padding-top: 60px;
}
// 员工活动
.staff_activities {
  overflow: hidden;
  .top {
    height: 558px;
    background: url('../assets/img/member/member_bg.png') no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    animation: slide-in-top 0.7s;
    .title {
      margin-top: 142px;
      height: 40px;
      font-size: 28px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #010c29;
      line-height: 40px;
      text-align: center;
      animation: slide-in-top 1.3s;
    }
    .i {
      display: block;
      margin: 60px auto 24px;
      width: 28px;
      height: 1px;
      background-color: #788398;
      animation: slide-in-top 1.3s;
    }
    .span {
      display: block;
      height: 126px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #788398;
      line-height: 42px;
      text-align: center;
      animation: slide-in-top 1.3s;
    }
  }

  // 头部渐入
  @-webkit-keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-100px);
      transform: translateY(-100px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-100px);
      transform: translateY(-100px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  .content_box {
    margin-top: -65px;
    overflow: hidden;
    .box {
      width: 1220px;
      overflow: hidden;
      .item {
        float: left;
        margin-right: 20px;
        margin-bottom: 20px;
        padding-left: 52px;
        width: 580px;
        height: 320px;
        box-sizing: border-box;
        transition: all 1s;
        .line {
          margin-top: 68px;
          display: block;
          width: 20px;
          height: 2px;
          background-color: #ffffff;
        }
        .tit {
          display: block;
          margin-top: 120px;
          height: 30px;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 30px;
        }
        .text {
          display: block;
          margin-top: 12px;
          // width: 140px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
          opacity: 0.6;
        }
      }
      .dOne {
        padding-left: 0;
        background: url('../assets/img/member/member_conpany.png') no-repeat;
        background-size: 100% 100%;
        transition: all 1s;
        .line {
          margin-left: 52px;
        }
        .tit {
          padding-left: 52px;
        }
        .text {
          padding-left: 52px;
        }
      }
      .dTwo {
        background: url('../assets/img/member/member_anniversary.png') no-repeat;
        background-size: 100% 100%;
        transition: all 1s;
      }
      .dThree {
        padding-left: 0;
        background: url('../assets/img/member/member_new.png') no-repeat;
        background-size: 100% 100%;
        transition: all 1s;

        .line {
          margin-left: 52px;
        }
        .tit {
          padding-left: 52px;
        }
        .text {
          padding-left: 52px;
        }
      }
      .dFour {
        background: url('../assets/img/member/member_keep.png') no-repeat;
        background-size: 100% 100%;
        transition: all 1s;
      }
    }
    .active0 {
      .dOne {
        padding-left: 0;
        width: 840px;
        .hover_tit {
          padding-left: 52px;
          height: 68px;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #212632;
          line-height: 68px;
          background: #fbfbfb;
          border: 1px solid #edf0f5;
          border-top: 2px solid #8f98a9;
          box-sizing: border-box;
        }
        img {
          width: 100%;
          height: 252px;
        }
      }
      .dTwo {
        width: 320px;
        background-image: url('../assets/img/member/member_anniversary_03.png');
      }
    }
    .active1 {
      .dOne {
        width: 320px;
        background-image: url('../assets/img/member/member_conpany_03.png');
      }
      .dTwo {
        width: 840px;
        padding-left: 0;
        .hover_tit {
          padding-left: 52px;
          height: 68px;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #212632;
          line-height: 68px;
          background: #fbfbfb;
          border: 1px solid #edf0f5;
          border-top: 2px solid #8f98a9;
          box-sizing: border-box;
        }
        img {
          width: 100%;
          height: 252px;
        }
      }
    }
    .active2 {
      .dThree {
        padding-left: 0;
        width: 840px;
        .hover_tit {
          padding-left: 52px;
          height: 68px;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #212632;
          line-height: 68px;
          background: #fbfbfb;
          border: 1px solid #edf0f5;
          border-top: 2px solid #8f98a9;
          box-sizing: border-box;
        }
        img {
          width: 100%;
          height: 252px;
          // height: 100%;
        }
      }
      .dFour {
        width: 320px;
        background-image: url('../assets/img/member/member_keep_03.png');
      }
    }
    .active3 {
      .dThree {
        width: 320px;
        background-image: url('../assets/img/member/member_new_03.png');
      }
      .dFour {
        width: 840px;
        padding-left: 0;
        .hover_tit {
          padding-left: 52px;
          height: 68px;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #212632;
          line-height: 68px;
          background: #fbfbfb;
          border: 1px solid #edf0f5;
          border-top: 2px solid #8f98a9;
          box-sizing: border-box;
        }
        img {
          width: 100%;
          height: 252px;
        }
      }
    }
  }
}

// 办公环境
.office {
  overflow: hidden;
  .title {
    margin-top: 116px;
    height: 56px;
    font-size: 28px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #010c29;
    line-height: 56px;
    text-align: center;
  }
  .i {
    display: block;
    margin: 60px auto 24px;
    width: 28px;
    height: 1px;
    background: #788398;
  }
  .span {
    display: block;
    margin-top: 24px;
    height: 84px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #788398;
    line-height: 28px;
    text-align: center;
  }
  .contentBox {
    margin-top: 100px;
    margin-bottom: 120px;
    overflow: hidden;
    ul {
      width: 1220px;
      overflow: hidden;
      li {
        margin-right: 20px;
        float: left;
        width: 380px;
        img {
          width: 100%;
          height: 214px;
        }
        .tit {
          display: block;
          margin-top: 24px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #676f86;
          line-height: 20px;
          text-align: center;
        }
      }
    }
  }
}
</style>
